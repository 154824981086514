import { MessageBody } from "@/types/websocket";
export class AppWebSocket extends WebSocket {
  public timeout = 60_000;
  public schedule?: number;
  public token: string;

  constructor(url: string, token: string, protocols?: string | string[]) {
    super(url, protocols);
    this.token = token;

    this.onopen = () => {
      this.scheduleHearthBeat();
    };

    this.onclose = () => {
      clearInterval(this.schedule);
    };
  }

  private scheduleHearthBeat() {
    if (this.schedule) {
      clearInterval(this.schedule);
    }

    this.schedule = window.setInterval(this.heartBeat.bind(this), this.timeout);
  }

  public heartBeat() {
    this.send(
      JSON.stringify({
        action: "HEARTHBEAT",
        message: "PING",
      }),
    );
  }

  /**
   * Fire event to websocket
   * @param message {object | string}
   */
  public emit(payload: MessageBody) {
    this.send(
      JSON.stringify({
        ...payload,
        token: this.token,
      }),
    );
    this.scheduleHearthBeat();
  }
}

// declare module 'vue/types/vue' {
//   interface Vue {
//     $socket: AppWebSocket;
//   }
// }
