import { Store } from "vuex";
import { AppStore } from "@/types/store";
import { ACTION, METHOD } from "@/types/websocket";

export const websocketPlugin = () => {
  return (store: Store<AppStore>) => {
    const messageEvent = (message: MessageEvent) => {
      const { action, payload, type } = JSON.parse(message.data);
      store.state.loading = false;

      switch (action) {
        case ACTION.REPORT:
          store.state.loading = false;

          if (payload.kind) {
            console.log(payload.kind);
            return store.commit("reports/SET_DATA", payload.report);
          }
          store.commit("dashboard/SET_DATA", payload);
          break;

        case ACTION.INIT:
          store.state.loading = false;
          store.commit("settings/SET_SETTINGS", { ...payload.settings });
          store.commit("users/SET_CONNECTIONS", payload.activeUsers);
          break;

        case ACTION.SETTINGS:
          console.log(payload);
          break;

        case ACTION.CLIENTS:
          console.log(payload);

          if (payload) {
            switch (type) {
              case METHOD.GET:
                store.commit("clients/SET_CLIENTS", payload);
                break;
              case METHOD.PUSH:
                store.commit("entries/UPDATE_ENTRIES", payload.entries);
                store.commit("clients/SET_CLIENT", payload.client);
                break;
            }
          }
          break;

        case ACTION.VENDORS:
          store.state.loading = false;
          console.log(payload);

          if (payload) {
            switch (type) {
              case METHOD.GET:
                store.commit("vendors/SET_VENDORS", payload);
                break;
              case METHOD.PUSH:
                store.commit("vendors/SET_VENDOR", payload);
                break;
            }
          }
          break;

        case ACTION.USERS:
          store.state.loading = false;

          switch (type) {
            case METHOD.GET:
              store.commit("users/SET_USERS", payload);
              break;

            case METHOD.POST:
              store.commit("users/NEW_USER", payload);
              break;
          }
          break;

        case ACTION.ENTRY:
          store.state.loading = false;
          console.log(payload);
          if (payload) {
            switch (type) {
              case METHOD.GET_ALL:
                store.state.loading = true;

                if (payload.chunked && payload.count) {
                  store.commit("entries/SET_ALL_ENTRIES", payload);
                } else {
                  store.commit("entries/SET_STATE", true);
                }

                if (payload.count === 0) {
                  store.commit("entries/SET_ENTRIES", {
                    count: 0,
                    items: [],
                  });
                }

                if ((payload.chunk && payload.filtered) || !payload.chunk) {
                  store.commit(
                    "entries/PUSH_FILTERED_CHUNK",
                    payload.chunk || [],
                  );
                }

                if (payload.chunk && !payload.filtered) {
                  store.commit("entries/PUSH_ALL_CHUNK", payload.chunk);
                }

                if (payload.lastChunk || payload.count === 0) {
                  store.state.loading = false;
                }

                break;

              case METHOD.GET:
                if (payload.chunked && payload.count) {
                  store.commit("entries/SET_ENTRIES", payload);
                } else {
                  store.commit("entries/SET_STATE", true);
                }

                if (payload.count === 0) {
                  store.commit("entries/SET_ENTRIES", {
                    count: 0,
                    items: [],
                  });
                }

                if (payload.chunk) {
                  store.commit("entries/PUSH_CHUNK", payload.chunk);
                }
                break;

              case METHOD.PUSH:
                if (
                  payload.clientID !== store.getters["clients/currentClient"].id
                )
                  break;
                store.dispatch(
                  "pushNotification",
                  `${payload.user} edited entry ${payload.entry.name}`,
                );
                store.commit("entries/COMMIT_ENTRY", payload.entry);
                break;

              case METHOD.PUT:
                store.commit("users/SET_ACTIVEUSER", payload);
                break;
            }
          }
          break;

        case ACTION.COSTS:
          if (payload) {
            switch (type) {
              case METHOD.GET:
                store.commit("costs/SET_COSTS", payload);
                break;
              case METHOD.PUSH:
                store.commit("costs/SET_COST", payload);
                break;
            }
          }
          break;

        case ACTION.MESSAGE:
          console.log(payload);
          store.dispatch("pushNotification", payload.message);
          break;
      }
    };

    store.subscribe(({ type }, state) => {
      try {
        switch (type) {
          case "SET_WS":
            state.ws?.addEventListener("open", () => {
              store.commit("CONNECTION", true);
              store.state.loading = true;
              // GET SETTINGS FROM API
              state.ws?.emit({
                action: ACTION.INIT,
                payload: {
                  cognitoId: store.getters["auth/user"].username,
                },
              });
              state.ws?.emit({
                action: ACTION.CLIENTS,
                type: METHOD.GET,
              });
              state.ws?.emit({
                action: ACTION.VENDORS,
                type: METHOD.GET,
              });
              state.ws?.addEventListener("message", messageEvent);
              state.ws?.addEventListener("close", () => {
                store.commit("CONNECTION", false);
                store.commit("SET_WS", null);
              });
            });
            break;
        }
      } catch (e) {
        console.error(e);
      }
    });
  };
};
