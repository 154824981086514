import Vue from "vue";
import Vuex from "vuex";
import { websocket } from "@/config";
import { AppWebSocket } from "@/helpers/websocket";
import { AppStore } from "@/types/store";
import { websocketPlugin } from "@/plugins/websocket";

import AuthModule from "./auth.store";
import EntriesModule from "./entries.store";
import SettingsModule from "./settings.store";
import InvoiceModule from "./invoice.store";
import ClientsModule from "./clients.store";
import UsersModule from "./users.store";
import DashboardModule from "./dashboard.store";
import VendorsModule from "./vendors.store";
import ReportsModule from "./reports.store";
import CostsModule from "./costs.store";

import { MessageBody } from "@/types/websocket";

Vue.use(Vuex);

export const store = new Vuex.Store<AppStore>({
  state: {
    loading: false,
    ws: null,
    connected: false,
    message: "",
    _notifications: [],
    disableSelectClient: false,
  },

  getters: {
    notifications: (state) => state._notifications,
    loading: (state) => state.loading,
  },

  mutations: {
    SET_WS: (state, payload: AppWebSocket) => (state.ws = payload),
    CONNECTION: (state, payload) => (state.connected = payload),
    NOTIFICATION: (state, payload) => state._notifications.push(payload),
  },

  actions: {
    setWebSocket: ({ commit }) => {
      try {
        const ws = new AppWebSocket(
          `${websocket}?token=${store.getters["auth/token"]}`,
          store.getters["auth/token"],
        );
        commit("SET_WS", ws);
      } catch (e) {
        console.log(e);
        window.location.reload;
      }
    },
    emit: ({ state }, payload: MessageBody) => {
      // state.loading = true;
      state.ws?.emit(payload);
    },
    reconnect: ({ state }) => {
      try {
        if (state.ws?.OPEN) state.ws.close();

        const ws = new AppWebSocket(
          `${websocket}?token=${store.getters["auth/token"]}`,
          store.getters["auth/token"],
        );
        state.ws = ws;
        state.connected = true;
      } catch (e) {
        console.log(e);
        window.location.reload;
      }
    },
    pushNotification: ({ commit }, notification) => {
      Vue.prototype.$notify({
        group: "n",
        title: "Important message",
        type: "info",
        text: notification,
      });
      commit("NOTIFICATION", notification);
    },

    showSnackbar: ({ state }, message) => {
      state.message = message;
    },
  },

  modules: {
    auth: AuthModule,
    entries: EntriesModule,
    settings: SettingsModule,
    invoice: InvoiceModule,
    clients: ClientsModule,
    users: UsersModule,
    dashboard: DashboardModule,
    vendors: VendorsModule,
    reports: ReportsModule,
    costs: CostsModule,
  },
  plugins: [websocketPlugin()],
});
