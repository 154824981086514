import { formatDate } from "@/helpers/formaters";
import { shiftArray } from "@/helpers/generic";
import { CurrencyProps } from "@/types/invoice";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Settings extends VuexModule {
  public cacheExchange: any[] = [];
  public _projectTypes = ["Commerce", "Creative", "Production"];
  public _pg: boolean = false;
  public _financeStatus = [
    "Pending",
    "Ready for invoicing",
    "Sent for invoicing",
    "Invoiced",
    "Ready for accruing",
    "Sent for accruing",
    "Accrued",
  ];
  public _projectStatus = [
    {
      text: "New / In planning",
      value: 0,
    },
    {
      text: "In progress",
      value: 1,
    },
    {
      text: "Cancelled",
      value: 3,
    },
    {
      text: "On hold",
      value: 4,
    },
    {
      text: "Done",
      value: 5,
    },
  ];
  public _quarterList = ["JFM", "AMJ", "JAS", "OND"];
  public _marketList = ["UK", "IT"];
  public _costType = ["Print", "Digital IT", "Video", "Landing Page", "Social"];
  public _currencyList = [
    {
      text: "PLN",
      value: "pln",
    },
    {
      text: "EUR",
      value: "eur",
    },
    {
      text: "USD",
      value: "usd",
    },
    {
      text: "GBP",
      value: "gbp",
    },
  ];

  public _typeList = ["Generic", "Reserves", "Prodigious"];
  public _invoiceHandlerHeader = [
    {
      text: "Invoice Handler",
      align: "end",
      sortable: true,
      value: "invoiceHandler",
      type: "string",
      name: "invoiceHandler",
    },
  ];
  public _quarterHeaders = [
    {
      text: "JFM",
      align: "end",
      sortable: true,
      value: "jfm",
      type: "string",
    },
    {
      text: "AMJ",
      align: "end",
      sortable: true,
      value: "amj",
      type: "string",
    },
    {
      text: "JAS",
      align: "end",
      sortable: true,
      value: "jas",
      type: "string",
    },
    {
      text: "OND",
      align: "end",
      sortable: true,
      value: "ond",
      type: "string",
    },
  ];

  public _specificColumns = [
    {
      text: "RoarX project number",
      align: "end",
      sortable: true,
      value: "roarXProjectNumber",
      type: "string",
      name: "roarX",
    },
    {
      text: "Altair JOB number",
      align: "end",
      sortable: true,
      value: "altairJobNumber",
      type: "string",
      name: "altair",
    },
    {
      text: "Mondelez PO number",
      align: "end",
      sortable: true,
      value: "mondelezPoNumber",
      type: "string",
      name: "mondelez",
    },
    {
      text: "Brief ID",
      align: "end",
      sortable: true,
      value: "briefId",
      type: "string",
      name: "brief",
    },
    {
      text: "Cost Center",
      align: "end",
      sortable: true,
      value: "costCenter",
      type: "string",
      name: "costCenter",
    },
    {
      text: "WBS",
      align: "end",
      sortable: true,
      value: "wbs",
      type: "string",
      name: "wbs",
    },
    {
      text: "Client approval month",
      align: "start",
      sortable: true,
      value: "clientApprovalMonth",
      type: "string",
      name: "clientApprovalMonth",
    },
  ];

  public _costColumns = [
    {
      text: "Translate + Costs",
      align: "end",
      sortable: true,
      value: "translateCosts",
      type: "string",
      name: "additionalCosts",
    },
    {
      text: "Total CE value",
      align: "end",
      sortable: true,
      value: "costEstimateValue",
      type: "string",
      name: "additionalCosts",
    },
    {
      text: "Total Costs",
      align: "end",
      sortable: true,
      value: "sumCost",
      type: "string",
      name: "totalCosts",
    },
    {
      text: "Intercompany cost",
      align: "end",
      sortable: true,
      value: "totalProdigius",
      type: "string",
      name: "projectCost",
    },
    {
      text: "External cost",
      align: "end",
      sortable: true,
      value: "totalCost",
      type: "string",
      name: "projectCost",
    },
    {
      text: "Revenue Net (Currency)",
      align: "end",
      sortable: true,
      value: "revenueValue",
      sum: true,
      type: "string",
      name: "reveNet",
    },
    {
      text: "Discount Fee",
      align: "end",
      sortable: true,
      value: "discountFee",
      sum: true,
      type: "string",
      name: "discountFee",
    },
    {
      text: "Rush Fee",
      align: "end",
      sortable: true,
      value: "rushFee",
      sum: true,
      type: "string",
      name: "rushFee",
    },
    {
      text: "BA cost",
      align: "end",
      sortable: true,
      value: "baCost",
      sum: true,
      type: "string",
      name: "baCost",
    },
  ];
  public _projectTableHeaders = [
    {
      text: "Market",
      align: "start",
      sortable: true,
      value: "market",
      type: "list",
    },
    {
      text: "Project name",
      align: "start",
      sortable: true,
      value: "name",
      type: "string",
    },

    {
      text: "Project lead",
      align: "start",
      sortable: true,
      value: "projectLead",
      type: "list",
    },
    {
      text: "Client lead",
      align: "start",
      sortable: true,
      value: "clientLead",
      type: "list",
    },
    {
      text: "Project status",
      align: "start",
      sortable: true,
      value: "status",
      type: "list",
    },
    {
      text: "Budgeting month",
      align: "start",
      sortable: true,
      value: "budgetingMonth",
      type: "date",
    },
    {
      text: "PO delayed",
      align: "start",
      sortable: true,
      value: "poDelayed",
      type: "string",
    },
    {
      text: "Invoicing Request Month",
      align: "start",
      sortable: true,
      value: "invoiceMonth",
      type: "date",
    },
    {
      text: "Project estimate",
      align: "end",
      sortable: true,
      value: "estimate",
      sum: true,
      type: "string",
    },
    {
      text: "Project revenue",
      align: "end",
      sortable: true,
      value: "revenue",
      sum: true,
      type: "string",
    },

    // {
    //   text: "Intercompany cost",
    //   align: "end",
    //   sortable: true,
    //   value: "totalProdigius",
    //   sum: true
    // },
    // {
    //   text: "Project costs",
    //   align: "end",
    //   sortable: true,
    //   value: "costs",
    //   sum: true,
    //   type: "string"
    // },
    {
      text: "Job number",
      align: "end",
      sortable: true,
      value: "jobNumber",
      type: "string",
    },
    {
      text: "PO number",
      align: "end",
      sortable: true,
      value: "po",
      type: "string",
    },
    {
      text: "Finance status",
      align: "start",
      sortable: true,
      value: "financeStatus",
      type: "list",
    },
    {
      text: "Practice",
      align: "start",
      sortable: true,
      value: "stream",
      type: "list",
    },
    {
      text: "Brand",
      align: "start",
      sortable: true,
      value: "brandType",
      type: "list",
    },
    {
      text: "Region",
      align: "start",
      sortable: true,
      value: "regionList",
      type: "list",
    },
    {
      text: "Project type",
      align: "start",
      sortable: true,
      value: "projectType",
      type: "list",
    },
    {
      text: "Work type",
      align: "start",
      sortable: true,
      value: "workType",
      type: "list",
    },
    {
      text: "Campaign Name",
      align: "start",
      sortable: true,
      value: "campaignName",
      type: "string",
    },
    {
      text: "Category type",
      align: "start",
      sortable: true,
      value: "category",
      type: "list",
    },
    {
      text: "Comments",
      align: "start",
      sortable: true,
      value: "comment",
      type: "string",
    },
    {
      text: "Final CE",
      align: "start",
      sortable: true,
      value: "finalCE",
      type: "string",
    },
    // {
    //   text: "Vendor POs",
    //   align: "start",
    //   sortable: true,
    //   value: "vendorPos",
    //   type: "string"
    // }
  ];

  public _fiscalYears = [
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
  ];

  public _months = [
    {
      abbreviation: "Jan",
      name: "January",
      number: 1,
    },
    {
      abbreviation: "Feb",
      name: "February",
      number: 2,
    },
    {
      abbreviation: "Mar",
      name: "March",
      number: 3,
    },
    {
      abbreviation: "Apr",
      name: "April",
      number: 4,
    },
    {
      abbreviation: "May",
      name: "May",
      number: 5,
    },
    {
      abbreviation: "Jun",
      name: "June",
      number: 6,
    },
    {
      abbreviation: "Jul",
      name: "July",
      number: 7,
    },
    {
      abbreviation: "Aug",
      name: "August",
      number: 8,
    },
    {
      abbreviation: "Sep",
      name: "September",
      number: 9,
    },
    {
      abbreviation: "Oct",
      name: "October",
      number: 10,
    },
    {
      abbreviation: "Nov",
      name: "November",
      number: 11,
    },
    {
      abbreviation: "Dec",
      name: "December",
      number: 12,
    },
  ];

  public get specificColumns() {
    return this._specificColumns;
  }

  public get costColumns() {
    return this._costColumns;
  }
  public get invoiceHandlerHeader() {
    return this._invoiceHandlerHeader;
  }
  public get quarterList() {
    return this._quarterList;
  }
  public get quarterHeaders() {
    return this._quarterHeaders;
  }

  public get projectTableHeaders() {
    return this._projectTableHeaders;
  }

  public get projectTypes() {
    return this._projectTypes;
  }

  public get projectStatus() {
    return this._projectStatus;
  }

  public get financeStatus() {
    return this._financeStatus;
  }

  public get marketList() {
    return this._marketList;
  }

  public get costType() {
    return this._costType;
  }

  public get currencyList() {
    return this._currencyList;
  }

  public get typeList() {
    return this._typeList;
  }

  public get fiscalYears() {
    return this._fiscalYears;
  }

  public get months() {
    if (this._pg) return shiftArray(this._months, 6);

    return this._months;
  }

  public get revenueModel() {
    return [
      {
        text: "N/A",
      },
      {
        text: "PGD",
      },
    ];
  }

  public get poNumber() {
    return [];
  }

  public get pg() {
    return this._pg;
  }

  public get monthsList() {
    if (this._pg) return [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6];

    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  public get displayYear() {
    return (selectedYear: number, month?: number) => {
      const currentMonth = month || new Date().getMonth();

      if (!this._pg) return selectedYear;
      if (currentMonth > 5) return `${selectedYear}/${selectedYear + 1}`;

      return `${selectedYear - 1}/${selectedYear}`;
    };
  }

  public get correctYear() {
    return (selectedYear: number, month: number) => {
      const currentMonth = new Date().getMonth();

      if (!this._pg) return selectedYear;
      if (currentMonth > 5 && month < 7) return selectedYear + 1;
      if (currentMonth > 5) return selectedYear;
      if (month < 7) return selectedYear;

      return selectedYear - 1;
    };
  }

  @Mutation
  public SET_EXCHANGE(exchange: any) {
    this.cacheExchange.push(exchange);
  }

  @Mutation
  public SET_PG(data: boolean) {
    this._pg = data;
  }

  @Action
  public setPG(data: boolean) {
    this.context.commit("SET_PG", data);
  }

  @Action
  public async fetchExchange({
    currency,
    budgetingMonth,
  }: CurrencyProps): Promise<number> {
    try {
      if (!currency) throw new Error();
      const cached = this.cacheExchange.find(
        (exchange) =>
          exchange.currency === currency &&
          exchange.budgetingMonth === budgetingMonth,
      );

      console.log({ cached });
      if (cached) {
        return cached.value;
      }
      const rate = await fetch(
        `https://api.nbp.pl/api/exchangerates/rates/a/${currency}/${
          budgetingMonth ? budgetingMonth : "last/2"
        }/?format=json`,
        {
          headers: {
            accept: "*/*",
          },
        },
      ).then((r) => r.json());
      const val = rate.rates.filter(
        (rate: any) => rate.effectiveDate !== formatDate(new Date()),
      )[0];
      if (
        !this.cacheExchange.find(
          (exchange) =>
            exchange.currency === currency && exchange.budgetingMonth,
        )
      ) {
        this.context.commit("SET_EXCHANGE", {
          currency,
          budgetingMonth: val.effectiveDate,
          value: val.mid,
        });
      }
      return val.mid;
    } catch (error) {
      console.error(error);
      return 0;
      // const date = new Date(budgetingMonth || '');
      // date.setDate(date.getDate() - 1);
      // return this.fetchExchange({ currency, budgetingMonth: formatDate(date) });
    }
  }
}
