export enum ACTION {
  INIT = "INIT",
  ENTRY = "ENTRY",
  SETTINGS = "SETTINGS",
  CLIENTS = "CLIENTS",
  USERS = "USERS",
  VENDORS = "VENDORS",
  REPORT = "REPORT",
  COSTS = "COSTS",
  MESSAGE = "MESSAGE",

  // HEARTHBEAT = "HEARTHBEAT",
}

export interface MessageBody {
  type?: METHOD;
  action: ACTION;
  payload?: any;
}

export enum METHOD {
  GET = "GET",
  GET_ALL = "GET_ALL",
  POST = "POST",
  PUSH = "PUSH",
  PUT = "PUT",
  DELETE = "DELETE",
}
