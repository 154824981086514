import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import {
  Entry,
  EntriesItems,
  IfetchEntries,
  IallEntriesItems,
} from "@/types/entries.store";
import { ACTION, MessageBody, METHOD } from "@/types/websocket";
import { ProjectsFilterGroup } from "@/types/general";

@Module({ namespaced: true })
export default class Entries extends VuexModule {
  public loaded = false;

  public _entries: EntriesItems = {
    count: 0,
    items: [],
    lastEvaluatedKey: null,
  };

  public _allEntries: EntriesItems = {
    count: 0,
    items: [],
  };

  public _filteredEntries: EntriesItems = {
    count: 0,
    items: [],
  };

  public get lastEvaluatedKey() {
    return this._entries?.lastEvaluatedKey;
  }

  public get entries() {
    return this._entries?.items;
  }

  public get allEntries() {
    return this._allEntries?.items;
  }

  public get filteredEntries() {
    return this._filteredEntries?.items;
  }

  @Mutation
  public SET_STATE(state: boolean) {
    this.loaded = state;
  }

  @Mutation
  public UPDATE_ENTRIES(newEntries: any) {
    const updateEntries = (entries: Entry[]) =>
      entries.map(
        (existingEntry) =>
          newEntries.find(
            (newEntry: any) => existingEntry.id === newEntry.id,
          ) || existingEntry,
      );
    this._entries.items = updateEntries(this._entries.items);
    this._filteredEntries.items = updateEntries(this._filteredEntries.items);
  }

  @Mutation
  public SET_ENTRIES_EMPTY() {
    this._entries = { count: 0, items: [], lastEvaluatedKey: null };
    this._allEntries = { count: 0, items: [] };
    this._filteredEntries = { count: 0, items: [] };
  }

  @Mutation
  public SET_ALL_ENTRIES(entries: IallEntriesItems) {
    if (!this._allEntries.items.length && !entries.filtered) {
      this._allEntries.items = entries.items;
    }
    if (
      !this._filteredEntries.items.length ||
      (this._filteredEntries.items !== entries.items && entries.filtered)
    ) {
      this._filteredEntries.items = entries.items;
    }
    if (!entries.items) {
      this._filteredEntries.items = [];
    }
  }

  @Mutation
  public SET_ENTRIES(entries: EntriesItems) {
    if (!this._entries.items.length) {
      this._entries = entries;
    } else {
      entries.items.forEach((entry) => this._entries.items.push(entry));
      this._entries.lastEvaluatedKey = entries.lastEvaluatedKey;
    }
  }

  @Mutation
  public PUSH_CHUNK(chunk: Entry[]) {
    this._entries.items.push(...chunk);
    if (this._entries.items.length === this._entries.count) {
      this.loaded = true;
    }
  }

  @Mutation
  public PUSH_ALL_CHUNK(chunk: Entry[]) {
    this._allEntries.items.push(...chunk);
    if (this._allEntries.items.length === this._allEntries.count) {
      this.loaded = true;
    }
  }

  @Mutation
  public PUSH_FILTERED_CHUNK(chunk: Entry[]) {
    this._filteredEntries.items.push(...chunk);
    if (this._filteredEntries.items.length === this._filteredEntries.count) {
      this.loaded = true;
    }
    if (!chunk.length) {
      this._filteredEntries.items = [];
    }
  }

  @Mutation
  public COMMIT_ENTRY(entry: Entry) {
    const entryIndex = this._entries.items.findIndex((e) => e.id === entry.id);
    if (entryIndex > -1) {
      return this._entries.items.splice(entryIndex, 1, entry);
    }
    this._entries.items.unshift(entry);
  }

  @Mutation
  public COMMIT_ENTRY_FILTERED(entry: Entry) {
    const entryIndex = this._filteredEntries.items.findIndex(
      (e) => e.id === entry.id,
    );
    if (entryIndex > -1) {
      return this._filteredEntries.items.splice(entryIndex, 1, entry);
    }
    this._filteredEntries.items.unshift(entry);
  }

  @Action({ commit: "COMMIT_ENTRY" })
  public save(entry: any): void {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.ENTRY,
        type: METHOD.POST,
        payload: entry,
      } as MessageBody,
      { root: true },
    );
    return entry;
  }

  @Action({ commit: "COMMIT_ENTRY_FILTERED" })
  public saveFiltered(entry: any): void {
    return entry;
  }

  @Action
  public cleanEntries() {
    this.context.commit("SET_ENTRIES_EMPTY");
  }

  @Action
  public entry(id: string) {
    return this._entries.items.find((i) => i.id === id);
  }

  @Action
  public lockEntry({ entryID, status }: any) {
    if (!entryID) return;
    this.context.dispatch(
      "emit",
      {
        action: ACTION.ENTRY,
        type: METHOD.PUT,
        payload: {
          entryID,
          state: status,
        },
      },
      { root: true },
    );
  }

  @Action
  public fetchEntries({ clientID, lastEvaluatedKey }: IfetchEntries) {
    this.context.rootState.loading = true;
    this.context.commit("SET_STATE", false);
    this.context.dispatch(
      "emit",
      {
        action: ACTION.ENTRY,
        type: METHOD.GET,
        payload: {
          clientID,
          startKey: lastEvaluatedKey,
        },
      } as MessageBody,
      { root: true },
    );
  }

  @Action
  public fetchAllEntries({
    clientID,
    filters,
  }: {
    clientID: string;
    filters: ProjectsFilterGroup[];
  }) {
    this._filteredEntries.items = [];

    this.context.rootState.loading = true;
    this.context.commit("SET_STATE", false);
    this.context.dispatch(
      "emit",
      {
        action: ACTION.ENTRY,
        type: METHOD.GET_ALL,
        payload: {
          clientID,
          filters,
        },
      } as MessageBody,
      { root: true },
    );
  }
}
